import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    text: {
      dark: "#0F1203",
      light: "#FFF",
      disabled: "#4A4945",
      hint: "#0d6efd",
    },
    primary: {
      light: "#425175",
      main: "#333e5c",
      dark: "#292e42",
      contrastText: "#FFF",
    },
    secondary: {
      light: "#3c4ca8",
      main: "#2b3890",
      dark: "#1e277a",
      contrastText: "#FFF",
    },
    button: {
      default: "#333e5c",
      light: "#435072",
      dark: "#202a44",
    },
    background: {
      default: "#fff",
      paper: "#202833",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    h1: {
      fontsize: "3rem",
    },
    h2: {
      fontSize: "2.5rem",
    },
    h3: {
      fontSize: "2rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.25rem",
    },
    h6: {
      fontSize: "1rem",
    },
    body1: {
      fontSize: "1.5rem",
      fontWeight: "lighter",
    },
    body2: {
      fontSize: "1rem",
      letterSpacing: "0.0935em",
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: "#FFF",
        },
      },
    },
  },
});

export default theme;
