import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../src/styles/light/theme";
import logo from "../../src/assets/images/logo/icon.png";

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <title>Jawin Pty Ltd</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta
          name="description"
          content="Get started on your digital transformation journey today"
        />
        <meta name="image" content={logo} />
        <link rel="canonical" href="http://www.jawin.com.au" />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
