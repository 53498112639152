exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-claims-jsx": () => import("./../../../src/pages/claims.jsx" /* webpackChunkName: "component---src-pages-claims-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-digital-jsx": () => import("./../../../src/pages/digital.jsx" /* webpackChunkName: "component---src-pages-digital-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-programme-jsx": () => import("./../../../src/pages/programme.jsx" /* webpackChunkName: "component---src-pages-programme-jsx" */),
  "component---src-pages-sectors-jsx": () => import("./../../../src/pages/sectors.jsx" /* webpackChunkName: "component---src-pages-sectors-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

